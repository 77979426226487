///////////////////////////////////
// Main js file
///////////////////////////////////

AOS.init();

// Services accordion

$(document).ready(function($) {
  $('.services').find('.services__item').click(function(){

    //Expand or collapse this panel
    $(this).next().slideToggle('fast');
    $(this).toggleClass('services__item--active');

    //Hide the other panels
    $('.services__text').not($(this).next()).slideUp('fast');
    $('.services__item').not(this).removeClass('services__item--active');

  });
});



// Input search active
$('.btn--search[type=submit]').on('click', function(e) {
  e.preventDefault()

  let formFocus = $(this).parents('form');
  formFocus.toggleClass('form__search--focus');

});

// Active submit button

$('.input__search').on('input', function(e) {
  let inputValue = $(this).val().length; 
  let formBtn = $(this).prev('.form__search-btn');

  if (inputValue >= 1) {
    formBtn.addClass('form__search-btn--active');
    $('.form__search-btn .btn:first-child').prop( "disabled", true );
    $('.form__search-btn .btn:last-child').prop( "disabled", false );
  }

  else {
    formBtn.removeClass('form__search-btn--active');
    $('.form__search-btn .btn:first-child').prop( "disabled", false );
    $('.form__search-btn .btn:last-child').prop( "disabled", true );
  }
});

// Open search on focus button

$('.btn--search[type=submit]').on('focusin', function(e) {
  let formFocus = $(this).parents('form');

  formFocus.addClass('form__search--focus');

});


// Start cookieconsent 

window.addEventListener("load", function(){
  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#000000",
        "text": "#ffffff"
      },
      "button": {
        "background": "#FFE500",
        "text": "#000000",
        "border": "transparent"
      }
    },
    "position": "bottom-right",
    "content": {
      "message": "Táto stránka používa cookies, jej používaním súhlasíte s podmienkami zásady ochrany osobných údajov",
      "dismiss": "Súhlasim",
      "link": "Viac info",
      "href": "cookies.html"
    }
  })
});
